



























































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'CalPolyPracticeExam3Q1',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        explainedResponse: null,
      },
    };
  },
  computed: {
    elements(): string[] {
      return ['C and H', 'Na and F', 'P and O'];
    },
  },
};
